import React from 'react';
import ical from 'ical-generator';
import FileSaver from 'file-saver';
import addHours from 'date-fns/addHours';
import SurveyRSVP from './SurveyRSVP';

const SurveyRSVPWithState = ({onChange, company, ...props}) => {
  if (onChange === null) {
    return <SurveyRSVP onChange={onChange} {...props} />;
  }

  const handleAddToCalendar = () => {
    const {content} = props.survey;
    if (content) {
      const {dateTime, title, description, location} = content;
      const eventDateTime = new Date(dateTime);
      const event = {
        start: eventDateTime,
        end: addHours(eventDateTime, 1),
        summary: title || '',
        description: description || '',
        location: location || '',
      };

      const calendarBlob = ical({
        domain: 'meetroger.io',
        prodId: {company: company.name, product: 'meetroger'},
        timezone: 'Europe/Brussels',
        events: [event],
      }).toBlob();
      FileSaver.saveAs(calendarBlob, `${title}.ics`);
    }
  };

  return (
    <SurveyRSVP
      onAddToCalendar={handleAddToCalendar}
      onChange={onChange}
      {...props}
    />
  );
};

export default SurveyRSVPWithState;
